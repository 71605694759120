import styled from 'styled-components'

const PageContent = styled.div`
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  font-family: roboto-bold, roboto, sans-serif;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
`

const StyledDoll = styled.img`
  max-width: 512px;
  width: 35%;
`

const StyledBubble = styled.img<{ image: string }>`
  max-width: 512px;
  z-index: 10;
  width: 65%;
  background-image: url('${process.env.PUBLIC_URL}/images/${({ image }) =>
  image}');
  background-position: center;
   background-size: cover;
`

export { PageContent, StyledDoll, StyledBubble }
