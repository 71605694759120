import React, { useState } from 'react'
import { BaseContent, StyledTitle, CopyrightGray } from '../../style'
import { PageContent, GalleryContainer } from './style'
import { Spacer, SectionTitle } from '../../Components'
import Gallery from 'react-grid-gallery'

const IMAGES = [
  {
    src: `${process.env.PUBLIC_URL}/images/smooch_by_ponacho_dcmhzuy-fullview.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/images/smooch_by_ponacho_dcmhzuy-fullview.jpg`,
    thumbnailWidth: 101.8,
    thumbnailHeight: 118.4,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/happy_birthday_princess__by_ponacho_dcwchgv-fullview.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/images/happy_birthday_princess__by_ponacho_dcwchgv-fullview.jpg`,
    thumbnailWidth: 102.4,
    thumbnailHeight: 99.3,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/ponah_comin_to_beat_yo_ass_by_ponacho-dcg7oz1.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/images/ponah_comin_to_beat_yo_ass_by_ponacho-dcg7oz1.jpg`,
    thumbnailWidth: 94.5,
    thumbnailHeight: 84.5,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/rawr_by_ponacho_dclfhen-fullview.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/images/rawr_by_ponacho_dclfhen-fullview.jpg`,
    thumbnailWidth: 57.4,
    thumbnailHeight: 118.4,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/june_chibi_2_by_ponacho_dce5u0u-fullview.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/images/june_chibi_2_by_ponacho_dce5u0u-fullview.jpg`,
    thumbnailWidth: 102.4,
    thumbnailHeight: 83.4,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/fly_with_me_by_ponacho_dcmhzlx-fullview.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/images/fly_with_me_by_ponacho_dcmhzlx-fullview.jpg`,
    thumbnailWidth: 102.4,
    thumbnailHeight: 108.8,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/dczrt7u-46ad1303-fc7a-4a84-bd14-3a0f9de8c4f8.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/images/dczrt7u-46ad1303-fc7a-4a84-bd14-3a0f9de8c4f8.jpg`,
    thumbnailWidth: 102.4,
    thumbnailHeight: 117.3,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/a_gross_gremlin_by_ponacho_dcg7ozh-fullview.jpg`,
    thumbnail: `${process.env.PUBLIC_URL}/images/a_gross_gremlin_by_ponacho_dcg7ozh-fullview.jpg`,
    thumbnailWidth: 102.4,
    thumbnailHeight: 107.5,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/42a_orig.png`,
    thumbnail: `${process.env.PUBLIC_URL}/images/42a_orig.png`,
    thumbnailWidth: 82.7,
    thumbnailHeight: 80,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/25_2_orig.png`,
    thumbnail: `${process.env.PUBLIC_URL}/images/25_2_orig.png`,
    thumbnailWidth: 86,
    thumbnailHeight: 80,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/23_7_orig.png`,
    thumbnail: `${process.env.PUBLIC_URL}/images/23_7_orig.png`,
    thumbnailWidth: 75.8,
    thumbnailHeight: 90,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/4_3_orig.png`,
    thumbnail: `${process.env.PUBLIC_URL}/images/4_3_orig.png`,
    thumbnailWidth: 70.2,
    thumbnailHeight: 80,
  },
  {
    src: `${process.env.PUBLIC_URL}/images/3_3_orig.png`,
    thumbnail: `${process.env.PUBLIC_URL}/images/3_3_orig.png`,
    thumbnailWidth: 66.8,
    thumbnailHeight: 80,
  },
]

const Illustration = () => (
  <PageContent>
    <StyledTitle color="#EB8015">Illustration</StyledTitle>
    <Spacer spacing={64} />
    <SectionTitle title="2018 - 2019" />
    <GalleryContainer>
      <Gallery images={IMAGES} enableImageSelection={false} />
    </GalleryContainer>
  </PageContent>
)

export { Illustration }
