import React, { useEffect, useState } from 'react'
import { Spacer } from '../../Components'
import { PageContent, StyledDoll, StyledBubble } from './style'

const images = ['(1).png', '(2).png', '(3).png', '(4).png', '(5).png', '(6).png', '(7).png', '(8).png', '(9).png', '(10).png']

const Main = () => {
  const [currentImage, setCurrentImage] = useState(images[Math.floor(Math.random() * images.length)])
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage(images[Math.floor(Math.random() * images.length)])
    }, 3000)
    return () => clearInterval(timer)
  }, [])

  return (
    <PageContent>
      <StyledDoll src={`${process.env.PUBLIC_URL}/images/index/pagedoll.gif`} />
      <StyledBubble src={`${process.env.PUBLIC_URL}/images/index/bubble.gif`} image={`showreel/${currentImage}`} />
      <Spacer spacing={64} />
    </PageContent>
  )
}

export { Main }
