import React from 'react'
import { StyledTitle } from '../../style'
import { PageContent, Subsection } from './style'
import { ThirdwidthImage, Divider, SectionTitle, Spacer } from '../../Components'

const Animations = () => (
  <PageContent>
    <StyledTitle color="#EB8015">Animation</StyledTitle>
    <Spacer spacing={64} />
    <Subsection>
      <iframe src="https://www.youtube.com/embed/IHbaVlmvd8Q" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
      <SectionTitle title="Showreel 2020" />
    </Subsection>
    <Divider />
    <ThirdwidthImage src={`${process.env.PUBLIC_URL}/images/ani110_ass3_tute1_broomhall_sarah_jane_v2_by_ponacho-dd3fa7y.gif`} />
    <ThirdwidthImage src={`${process.env.PUBLIC_URL}/images/hamsterjump_by_ponacho-dd3fa6e.gif`} />
    <ThirdwidthImage src={`${process.env.PUBLIC_URL}/images/dd3uf58-a5d67522-005a-43e8-b78d-253a1de15a9a.gif`} />
    <ThirdwidthImage src={`${process.env.PUBLIC_URL}/images/dd3uf62-fd56ca77-84d1-4d3e-9e3b-0fc1e5d4a8d1.gif`} />
    <Spacer spacing={64} />
  </PageContent>
)

export { Animations }
