import React, { useEffect } from 'react'
import { StyledLinkGroup, StyledTopLink, LinkBorder } from '../../style'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from '../../hooks/useWindowSize'
import { StyledBurger, StyledMenu, StyledHeaderImage, StyledNavLinks } from './style'
import { createPortal } from 'react-dom'

const Portal: React.FC = ({ children }) => {
  const mount = document.getElementById('modal')
  const el = document.createElement('div')

  useEffect(() => {
    mount && mount.appendChild(el)
    return () => {
      mount && mount.removeChild(el)
    }
  }, [el, mount])

  return createPortal(children, el)
}

const NavLinks: React.FC<{ onClick?: () => void }> = React.memo(
  ({ onClick }) => {
    const { pathname } = useLocation()

    return (
      <>
        <StyledTopLink onClick={onClick} isCurrent={pathname === '/'} to="/">
          Home
        </StyledTopLink>
        <StyledTopLink onClick={onClick} isCurrent={pathname === '/projects'} to="/projects">
          Projects
        </StyledTopLink>
        <StyledTopLink onClick={onClick} isCurrent={pathname === '/animation'} to="/animation">
          Animation
        </StyledTopLink>
        <StyledTopLink onClick={onClick} isCurrent={pathname === '/illustration'} to="/illustration">
          Illustration
        </StyledTopLink>
        <StyledTopLink onClick={onClick} isCurrent={pathname === '/about'} to="/about">
          About / Contact
        </StyledTopLink>
      </>
    )
  },
  (prevProps, nextProps) => prevProps.onClick === nextProps.onClick
)

const HeaderImage: React.FC = () => {
  const [hover, setHover] = React.useState(false)

  return (
    <div>
      <StyledHeaderImage onMouseLeave={() => setHover(false)} onMouseEnter={() => setHover(true)} src={`${process.env.PUBLIC_URL}/images/header/header${hover ? '2' : '1'}.png`} />
    </div>
  )
}

const Header: React.FC<{ hideFooter?: boolean }> = ({ hideFooter }) => {
  const { mobile } = useWindowSize()
  const [open, setOpen] = React.useState(false)

  if (mobile) {
    return (
      <>
        <HeaderImage />
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>
        <Portal>
          <StyledMenu open={open}>
            <NavLinks
              onClick={() => {
                console.log('a')
                setOpen(!open)
              }}
            />
          </StyledMenu>
        </Portal>
      </>
    )
  }

  return (
    <StyledLinkGroup>
      <HeaderImage />
      <StyledNavLinks>
        <NavLinks />
      </StyledNavLinks>
      {!hideFooter && <LinkBorder />}
    </StyledLinkGroup>
  )
}

const MemoHeader = React.memo(Header, (prevProps, nextprops) => prevProps.hideFooter === nextprops.hideFooter)

export { MemoHeader as Header }
