import React from 'react'

import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import { Main } from './Pages/Main'
import { About } from './Pages/About'
import { Animations } from './Pages/Animations'
import { Illustration } from './Pages/Illustration'
import { Header } from './Components/Header'
import { BaseContent } from './style'
import { Footer } from './Components/Footer'
import { Projects } from './Pages/Projects'

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <BaseContent>
            <Header />
            <Switch>
              <Route exact path="/">
                <Main />
              </Route>
              <Route
                path="/projects"
                render={({ match: { url } }) => (
                  <Switch>
                    <Route path={`${url}/home`}>a</Route>
                    <Route path={`${url}/users`}>b</Route>
                    <Route path={`${url}/`}>
                      <Projects />
                    </Route>
                  </Switch>
                )}
              />
              <Route path="/animation">
                <Animations />
              </Route>
              <Route path="/illustration">
                <Illustration />
              </Route>
              <Route path="/about">
                <About />
              </Route>
            </Switch>
            <Footer />
          </BaseContent>
        </Switch>
      </Router>
    </div>
  )
}

export default App
