import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledFullwidthImage = styled.img`
  width: calc(100% - 20px);
  padding: 10px;
`

const StyledHalfwidthImage = styled.img`
  width: calc(50% - 20px);
  padding: 10px;

  @media all and (max-width: 736px) {
    width: calc(100% - 20px);
  }
`

const StyledFullwidthContainer = styled.p`
  width: calc(100% - 20px);
  display: inline-block;
`

const StyledHalfwidthContainer = styled.div`
  width: calc(50% - 20px);
  display: inline-block;

  @media all and (max-width: 736px) {
    width: calc(100% - 20px);
  }
`

const StyledThirdwidthImage = styled.img`
  width: calc(33.33% - 20px);
  padding: 10px;

  @media all and (max-width: 1024px) {
    width: calc(50% - 20px);
  }

  @media all and (max-width: 736px) {
    width: calc(100% - 20px);
  }
`

const StyledThirdwidthContainer = styled.div`
  width: calc(33.33% - 20px);
  display: inline-block;

  @media all and (max-width: 1024px) {
    width: calc(50% - 20px);
  }

  @media all and (max-width: 736px) {
    width: calc(100% - 20px);
  }
`

const StyledTitle = styled.p`
  padding: 10px;
  margin: 0;
  text-align: center;
`

const StyledSubtitle = styled.p`
  padding: 10px;
  margin: 0;
  text-align: center;
`

const StyledImg = styled.img`
  padding: 10px;
  width: 100%;
`

const UnstyledLink = styled(Link)`
  display: contents;
  text-decoration: none;
  color: inherit;
`

const ThirdwidthImage = (props: any) => {
  if (props.title || props.subtitle) {
    return (
      <StyledThirdwidthContainer>
        <StyledImg {...props} />
        <StyledTitle>{props.title}</StyledTitle>
        <StyledSubtitle>{props.subtitle}</StyledSubtitle>
      </StyledThirdwidthContainer>
    )
  }

  return <StyledThirdwidthImage {...props} />
}

const HalfwidthImage = (props: any) => {
  if (props.title) {
    return (
      <StyledHalfwidthContainer>
        <StyledHalfwidthImage {...props} />
        <StyledTitle>{props.title}</StyledTitle>
      </StyledHalfwidthContainer>
    )
  }

  return <StyledHalfwidthImage {...props} />
}

const FullwidthImage = (props: any) => {
  if (props.title) {
    return (
      <StyledFullwidthContainer>
        <StyledFullwidthImage {...props} />
        <StyledTitle>{props.title}</StyledTitle>
      </StyledFullwidthContainer>
    )
  }

  return <StyledFullwidthImage {...props} />
}

export { FullwidthImage, HalfwidthImage, ThirdwidthImage, UnstyledLink }
