import { useState, useEffect } from 'react'

const useWindowSize = () => {
  const isClient = typeof window === 'object'

  function getSize () {
    if (isClient) {
      return {
        mobile: window.innerWidth <= 1024,
        desktop: window.innerWidth > 1024
      }
    }
    return {
      mobile: false,
      desktop: true
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return
    }

    function handleResize () {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export { useWindowSize }
