import React from 'react'
import { StyledTitle } from '../../style'
import { PageContent, Subsection } from './style'
import { FullwidthImage, Divider, SectionTitle, Spacer, ThirdwidthImage, UnstyledLink } from '../../Components'
import { Link } from 'react-router-dom'

const Projects = () => (
  <PageContent>
    <StyledTitle color="#EB8015">Projects</StyledTitle>
    <Spacer spacing={64} />
    <UnstyledLink to="/projects/home">
      <ThirdwidthImage title="project 1" subtitle="2020" src={`${process.env.PUBLIC_URL}/images/ani110_ass3_tute1_broomhall_sarah_jane_v2_by_ponacho-dd3fa7y.gif`} />
    </UnstyledLink>
    <ThirdwidthImage title="project 2" subtitle="2020" src={`${process.env.PUBLIC_URL}/images/hamsterjump_by_ponacho-dd3fa6e.gif`} />
    <ThirdwidthImage title="project 3" subtitle="2020" src={`${process.env.PUBLIC_URL}/images/dd3uf58-a5d67522-005a-43e8-b78d-253a1de15a9a.gif`} />
    <ThirdwidthImage title="project 4" subtitle="2020" src={`${process.env.PUBLIC_URL}/images/dd3uf62-fd56ca77-84d1-4d3e-9e3b-0fc1e5d4a8d1.gif`} />
    <Spacer spacing={64} />
  </PageContent>
)

export { Projects }
