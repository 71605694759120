import styled from 'styled-components'

const StyledBurger = styled.button<{ open: boolean }>`
  position: fixed;
  top: 2.5rem;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 2px;
    background: #0d0c1d;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

const StyledMenu = styled.nav<{ open: boolean }>`
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  text-align: left;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: 9;

  a {
    margin-right: 0;
    margin-left: 0;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 30px;
    text-align: center;
  }
`

const StyledHeaderImage = styled.img`
  width: 90%;
  max-width: 1024px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media all and (max-width: 1024px) {
    max-width: 250px;
  }
`

const StyledNavLinks = styled.div`
  width: fit-content;
  margin: auto;
`

export { StyledMenu, StyledBurger, StyledHeaderImage, StyledNavLinks }
