import React from 'react'
import { StyledIconGroup, CopyrightGray, StyledIcon } from '../../style'
import { faLinkedin, faArtstation, faTwitter, faInstagram, IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

const Media = ({ type, link, color = 'white' }: { type: IconDefinition; link: string; color?: string }) => {
  return (
    <a href={link}>
      <StyledIcon icon={type} mask={faCircle} size="2x" color={color} transform="shrink-6" />
    </a>
  )
}

const Footer: React.FC = () => {
  return (
    <>
      <StyledIconGroup>
        <Media color="gray" type={faLinkedin} link="https://www.linkedin.com/in/sarah-jane-broomhall/" />
        <Media color="gray" type={faArtstation} link="https://www.artstation.com/ponacho" />
        <Media color="gray" type={faTwitter} link="https://twitter.com/FabuPonah" />
        <Media color="gray" type={faInstagram} link="https://www.instagram.com/fabuponah/" />
      </StyledIconGroup>
      <CopyrightGray>© 2020 by Sarah-jane Broomhall</CopyrightGray>
    </>
  )
}

const MemoFooter = React.memo(Footer)

export { MemoFooter as Footer }
