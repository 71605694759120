import React from 'react'
import { StyledSectionTitle } from './style'

const SectionTitle = ({ title }: { title: string }) => (
  <StyledSectionTitle>
    <h1>{title}</h1>
  </StyledSectionTitle>
)

export { SectionTitle }
