import React from 'react'

import { StyledTitle } from '../../style'
import { PageContent, StyledProfilePicture, StyledAbout, StyledAboutText, StyledEmailText, StyledTitleText } from './style'

const About = () => {
  return (
    <PageContent>
      <StyledProfilePicture />
      <StyledAbout>
        <StyledTitle color="#256FB0">About me</StyledTitle>
        <StyledAboutText>
          <StyledTitleText>Sarah is an Australian artist from Brisbane QLD.</StyledTitleText>
          <br />
          <div>
            She enjoys drawing and designing cute and strange creatures, working with colours and experimenting. She also likes to learn new skills and is currently furthering her knowledge at SAE
            Institute Brisbane.
          </div>
          <div>
            Sarah uses a range of programs from Adobe Suite to Clip Studio Paint and Procreate, however an old favorite of hers to work in is Paint Tool Sai.
            <div>
              <br />
            </div>
            In her free time, Sarah likes to read graphic novels, draw anthro animals and try new food. She can often be found sitting in the botanic gardens watching the clouds go by.
          </div>
          <br />
          <br />
          <StyledEmailText>Email | sjbroomhall@hotmail.com</StyledEmailText>
        </StyledAboutText>
      </StyledAbout>
    </PageContent>
  )
}

export { About }
