import styled, { css } from 'styled-components'

const StyledSectionTitle = styled.div<{ fullwidth?: boolean }>`
  text-align: center;
  h1 {
    margin: 0;
    font-weight: 400;
    font-family: roboto-bold, roboto, sans-serif;
    font-size: 18px;
    color: #696969;

    @media all and (max-width: 736px) {
      font-size: 14px;
    }
  }
  p {
    margin: 0;
    margin-top: 4px;
    font-weight: 400;
    font-family: roboto-bold, roboto, sans-serif;
    font-size: 14px;
    color: #696969;

    @media all and (max-width: 736px) {
      font-size: 12px;
    }
  }
`

export { StyledSectionTitle }
