import styled, { css } from 'styled-components'

const PageContent = styled.div`
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  font-family: roboto-bold, roboto, sans-serif;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
`

const Subsection = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1000px;
  margin-left: 40px;
  margin-right: 40px;

  iframe {
    width: 100% !important;
    min-height: 530px;
    border: none;
  }

  @media all and (max-width: 1024px) {
    margin-left: 10px;
    margin-right: 10px;
    flex-direction: column;
    > * {
      margin-bottom: 20px;
    }
  }
`

export { PageContent, Subsection }
